import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Icon, Spacer, Skeleton, Button } from '@chakra-ui/react'
import Typography from '../../components/Common/Typography'
import { MdFileDownload } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom'
import { GoArrowLeft } from "react-icons/go";
import { isEmpty } from 'lodash';

import { callApiWithToken } from '../../utils/http_common'
import FiatSuccess from '../my-account/Prefund/FiatSuccess'
import { OrderDetailsColumn } from '../my-account/Prefund/WalletToWalletSuccess'
import StablecoinStepper from './StablecoinStepper'
import { getColorStatusFromType, iconAddMoney, iconPayout, StatusBadge } from './Transaction'
import PayoutSummary from './PayoutSummary'
import MainCard, { CardWithTitle } from '../../components/Common/MainCard'
import PayoutStepper from './PayoutStepper'
import { currencyFormatter } from '../../utils/formatter'
import OrderService from '../../services/createOrderService'
import DownloadableTransactionPDF from './DownloadableTransactionPDF'
import DownloadTransactionInitiate from './DownloadTransactionInitiate'

// this function is used to map the last status of the payout transaction
export const lastStatusMapPayout = (status, type) => {
    let finalStatus = '';
    switch (status) {
        case 'approved':
        case 'asset_processing':
            finalStatus = 'approved'
            break;
        case 'fund_processing':
        case 'asset_settled':
            finalStatus = 'fund_processing'
            break;
        default:
            finalStatus = status;
            break;
    }

    return finalStatus;
}

export const IconLabel = ({ currency, label, icon, boldLabel = false, bg = "#FFF4ED" }) => {
    return (
        <Flex columnGap={2} alignItems={"center"}>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} bg={bg} borderRadius={"100%"} width={"36px"} height={"36px"}>
                {<Icon as={icon} size={25} color='#FFC9A7' />}
            </Box>

            <Flex direction={"column"}>
                <Typography weight={boldLabel ? "bold" : "regular"} type='description'>
                    {label}
                </Typography>
                <Typography textAlign="left" weight='regular' type='caption' color='secondary'>
                    {currency}
                </Typography>
            </Flex>
        </Flex>
    )
}


export default function TransactionSummary() {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [summary, setSummary] = useState({});
    const [type, setType] = useState("")
    const [loading, setLoading] = useState(true)
    const [pdfLoading, setPdfLoading] = useState(false);
    const [lastStatus, setLastStatus] = useState(null);
    const intervalIdRef = useRef(null); // Ref to hold the interval ID
    const { filterType } = getColorStatusFromType(type || "collections", summary?.status ?? "initiated");

    const getOrderSummary = async () => {
        try {
            const response = await callApiWithToken.get(`/order/orderSummary/${orderId}`);
            if (response.data.success) {
                const data = response.data.data;
                setSummary(data);
                setType(data.type)
            }
        } catch (error) {
        } finally {

            setLoading(false)
        }
    };

    const checkOrderStatus = async () => {
        try {
            const res = await OrderService.getOrderStatus({ orderId });
            const currentStatus = res?.data?.data?.status;
            if (currentStatus !== lastStatus) {
                setLastStatus(currentStatus);  // Update the last status

                // Stop the interval if the status is completed or failed and there is no need to check further
                if (["fund_settled", "failed", "rejected", "fund_failed", "completed"].includes(currentStatus)) {
                    clearInterval(intervalIdRef.current);
                }

            }
        } catch (error) {
        }
    };

    const handleDownloadPdf = async (status) => {
        if (status === "fund_settled") {
            document.getElementById('pdf-download-button').click()
        } else {
            document.getElementById('pdf-download-button-initiate').click()
        }
    }

    useEffect(() => {
        const initFetch = async () => {
            // Initial check to set status and fetch summary once
            const res = await OrderService.getOrderStatus({ orderId });
            const initialStatus = res?.data?.data?.status;
            setLastStatus(initialStatus);  // Set the initial status
            // await getOrderSummary();  // Initial detailed fetch based on the first status
        };

        initFetch();

        intervalIdRef.current = setInterval(checkOrderStatus, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, [orderId]); // Dependencies only include orderId

    // Effect to handle when status changes
    useEffect(() => {
        if (lastStatus !== null) {
            getOrderSummary();  // Call this function whenever lastStatus changes
        }
    }, [lastStatus]); // Depend on lastStatus


    if (loading) return (
        <Box minH={"92vh"} p={"10px 40px"} bg={"#F2F2F2"}>
            <Flex w={"fit-content"} cursor={"pointer"} onClick={() => navigate(-1)} columnGap={2} alignItems={"center"}>
                <GoArrowLeft size={"13px"} />
                <Typography type="body">
                    Back
                </Typography>
            </Flex>
            <Typography type='largeheader' weight='bold' >
                Transaction
            </Typography>
            <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} >
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"756px"}>
                    <Skeleton height={"100px"} w={"100%"} />
                    <Skeleton height={"150px"} w={"100%"} />
                    <Skeleton height={"350px"} w={"100%"} />
                </Box>
            </Box>
        </Box>
    )

    return (
        <>
            <Box minH={"92vh"} p={"10px 40px"} bg={"#F2F2F2"}>
                <Flex w={"fit-content"} cursor={"pointer"} onClick={() => navigate(-1)} columnGap={2} alignItems={"center"}>
                    <GoArrowLeft size={"13px"} />
                    <Typography type="body">
                        Back
                    </Typography>
                </Flex>

                <Typography type='largeheader' weight='bold' >
                    Transactions
                </Typography>

                <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"800px"}>
                        <Flex mb={3} width={"100%"} flex={1} justifyContent={"space-between"} pr={3}>
                            <Flex columnGap={2} alignItems={"center"}>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={"100%"} width={"45px"} height={"45px"}>
                                    {<Icon as={() => filterType === "payout" ? iconPayout : iconAddMoney} />}
                                </Box>

                                <Flex direction={"column"}>
                                    <Typography textAlign="left" weight='bold'>
                                        {filterType === "payout" ?
                                            `Payment to ${summary?.recipientName?.firstName || ""} (${summary?.withdrawCurrency || ""} acc)` :
                                            "Add Money"}
                                    </Typography>
                                    <Typography textAlign="left" weight='regular' type='body' color='secondary'>
                                        {filterType === "payout" ?
                                            `Txn Id - ${summary?.orderId}` :
                                            `${summary.withdrawCurrency} Wallet`}
                                    </Typography>
                                </Flex>
                            </Flex>
                            <Flex alignItems={"center"} gap={1}>
                                {filterType === "payout" && !isEmpty(summary) ?
                                    <Button variant={"outline"} size={"md"} rightIcon={<MdFileDownload />} onClick={() => handleDownloadPdf(summary.status)} isDisabled={pdfLoading} >
                                        Download Receipt
                                    </Button>
                                    :
                                    <StatusBadge status={summary?.status} type={summary?.type} />
                                }
                            </Flex>
                        </Flex>

                        <Box w={"100%"}>
                            {
                                (type === "fiat_to_crypto_prefund" || type === "fiat_to_fiat_prefund" || type === "collections") &&
                                <Box w={"100%"}>
                                    <FiatSuccess isExternal={true} summary={summary} />
                                </Box>
                            }
                            {
                                (type === "crypto_to_crypto_prefund") && <>
                                    <MainCard isBorder={true}>
                                        <StablecoinStepper data={summary} />
                                    </MainCard>
                                    <Spacer h={19} />
                                    <CardWithTitle isBorder={true} title={"Payment Summary"}>
                                        <Flex flexDir={"column"}>
                                            <OrderDetailsColumn label={summary?.status === "completed" ? "We received your payment for" : "We are checking your payment for"} value={`${summary?.withdrawCurrency === "BTC" ? currencyFormatter(summary?.withdrawAmount, 5) : currencyFormatter(summary?.withdrawAmount)} ${summary?.withdrawCurrency}`} />
                                            <OrderDetailsColumn label="Processing fee" value={`${summary?.fees?.processingFee || 0} ${summary?.depositCurrency}`} />
                                            <OrderDetailsColumn label="Amount to be paid" value={`${summary?.depositCurrency === "BTC" ? currencyFormatter(summary?.depositAmount, 5) : currencyFormatter(summary?.depositAmount)} ${summary?.depositCurrency}`} />
                                            <OrderDetailsColumn label="Payment Method" value={`Wallet`} />
                                            {summary?.status === "completed" && <OrderDetailsColumn copy={true} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" w="400px" label="Txn hash" value={`${summary?.crypto?.orderId || ""}`} />}
                                        </Flex>

                                        {
                                            summary?.walletAddress && <>
                                                <hr style={{ marginTop: "40px" }} />

                                                <Typography pt="40px" pb="20px" textAlign="left" weight='medium' type='subtitle' color='primary' >
                                                    Additional Details
                                                </Typography>

                                                <Flex flexDir={"column"}>
                                                    <OrderDetailsColumn overflow="hidden" copy={true} whiteSpace="nowrap" textOverflow="ellipsis" w="400px" label="Wallet Address" value={`${summary?.walletAddress}`} />
                                                </Flex>
                                            </>
                                        }

                                    </CardWithTitle>

                                </>
                            }
                            {type && ["fiat_payout", "crypto_payout"].includes(type) &&
                                <>
                                    <MainCard isBorder={true}>
                                        <PayoutStepper data={summary} />
                                    </MainCard>

                                    <Spacer h={19} />

                                    <CardWithTitle isBorder={true} title={"Payment Summary"}>
                                        <PayoutSummary data={summary} />
                                    </CardWithTitle>
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <DownloadableTransactionPDF data={summary} setLoading={setPdfLoading} />
            <DownloadTransactionInitiate data={summary} setLoading={setPdfLoading} />
        </>
    )
}

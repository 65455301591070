import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    Text,
    HStack,
    Button,
} from "@chakra-ui/react";

import "./Home.css";

import Typography from "../../components/Common/Typography";
import AccountServide from "../../services/accountService";
import CollectionsHome from "../collections";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const HomeCombined = () => {
    const [balance, setBalance] = useState([]);
    const [loading, setLoading] = useState(true);

    const kybStatus = useSelector(state => state.login.orgDetails.kybStatus);

    const getMyAccounts = async () => {
        try {
            const res = await AccountServide.getMyAccountList();
            const filteredArray = res?.data?.data?.response.filter((d) => {
                return d.currency === "USD" || d.currency === "EUR";
            });
            setBalance(filteredArray);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMyAccounts();
    }, []);

    return (
        <Box className="sm-main-card">
            <Flex justify="space-between" align="center" mb={2}>
                <Typography type="largeheader" weight="bold">
                    Welcome to TransFi
                </Typography>
            </Flex>
            {/* kyb is intentionaly disabled for now */}
            {false ? <><KYBPendingCard /></> :
                <>
                    <GetStartedCard />
                    <CollectionsHome />
                </>
            }
        </Box>
    );
};

const GetStartedCard = () => {
    const navigate = useNavigate();

    const buttonProps = {
        size: "sm",
        color: "white",
        bg: "#3a62c9",
        _hover: { bg: "#4a73e0" },
        boxShadow: "md"
    };
    return (
        <Box bgColor="#194BCC" textColor="white" px="10" py="4" rounded="md">
            <Text fontSize="lg" fontWeight="bold">
                Hi There,
            </Text>
            <Text fontSize="small">
                Start by creating a contact to send and receive money
            </Text>
            <HStack spacing="6" paddingTop="6" paddingBottom="1">
                <Button {...buttonProps} onClick={() => navigate('/contact')}>Create Contact</Button>
                <Button {...buttonProps} onClick={() => navigate('/send-money')}>Send Money</Button>
                <Button {...buttonProps} onClick={() => navigate('/collection')}>Collect Money</Button>
            </HStack>
        </Box>
    )
}

const KYBPendingCard = () => {
    const buttonProps = {
        size: "sm",
        color: "#1A4FD6",
        boxShadow: "md"
    };
    return (
        <Box bgColor="#194BCC" textColor="white" px="10" py="4" rounded="md">
            <Text fontSize="22px" fontWeight="bold" mt="2">
                KYB verification is pending
            </Text>
            <Text fontSize="small" mt="2">
                Before going live in production you must provide your company information and sign the agreement to get KYB process implemented
            </Text>
            <HStack spacing="6" paddingTop="7" paddingBottom="4">
                <Button {...buttonProps}>Proceed to KYB Form</Button>
            </HStack>
        </Box>
    )
}

export default HomeCombined;

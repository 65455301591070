import './Contact.css'
import Typography from '../../components/Common/Typography';
import { Box, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import Contact from './Contact';
import Customer from '../customers/Customer';
import { useSearchParams } from 'react-router-dom';

const tabIndexes = {
    0 : "payout",
    1 : "collection",
}

const indexTabs = {
    "payout" : 0,
    "collection" : 1,
}

const ContactCombined = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab') || 'payout';
    const tabIndex = indexTabs[tab] ?? 0;

    return (
        <Box px="10" bg={'#F2F2F2'} minH={'110vh'}>
            <Typography mt="4" mb="3" color="default" type="largeheader" weight="bold">Contact</Typography>
            <Tabs tabIndex={tabIndex} defaultIndex={tabIndex} onChange={(e) => setSearchParams({tab : tabIndexes[e]})}>
                <TabList border="none">
                    <Tab
                        _selected={{
                            bg: "#ffffff",
                            color: "#1A4FD6"
                        }}
                        roundedTop="md"
                        color="#B3B3B3"
                    >
                        Payouts
                    </Tab>
                    <Tab
                        _selected={{
                            bg: "#ffffff",
                            color: "#1A4FD6"
                        }}
                        roundedTop="md"
                        color="#B3B3B3"
                    >
                        Collections
                    </Tab>
                </TabList>
                <TabPanels bg="white" px={10} py={4} roundedRight="md" roundedBottom="md">
                    {tabIndex === 0 && <Contact />}
                    {tabIndex === 1 && <Customer />}
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default ContactCombined
import { callApiWithToken } from "../utils/http_common";

const getBalanceList = () => {
  return callApiWithToken.get(`/account/list`);
}

const getCollectionOverview = (range) => {
  return callApiWithToken.get(`/transaction/collectionOverview?from=${range.from}&to=${range.to}`);
};


const BalanceService = {
  getBalanceList,
  getCollectionOverview
}

export default BalanceService;
import React, { useEffect, useState } from 'react'
import { Box, VStack, Tbody, Tr, Td, Flex, Img, Skeleton, Select, Image, Text } from '@chakra-ui/react'
import Typography from '../../components/Common/Typography'
import StickyTable from '../../components/Common/Table/StickyTable'
import BalanceService from '../../services/balanceService'
import { currencyFormatter } from '../../utils/formatter'
import EmptyIcon from '../../assets/emptypage.svg';
import moment from 'moment'

const fiatLabels = [
    {
        label: "Currency",
    },
    {
        label: "Total Amount Sent",
        // info: "Total Invoice amount you have sent to customers"
    },
    {
        label: "Total Amount Collected",
        // info: "Total amount of invoices that has been settled by TransFi to you"
    },
    {
        label: "Total Amount Settled",
        // info: "Total amount of invoices that has not been settled by TransFi to you"
    },
]


const TableColumn = (props) => {
    const { currency, logo, availableBalance, transitBalance, lockedBalance } = props
    if (!currency) return null;

    return (
        <Tr height={"46px"}>
            <Td>
                <Flex columnGap={2} alignItems={"center"}>
                    <Img src={encodeURIComponent(process.env.PUBLIC_URL + (logo || `/assets/crypto/${currency}.svg`))} alt={""} width={38} height={9} />
                    <Typography weight='regular' type='description'>{currency}</Typography>
                </Flex>
            </Td>
            <Td>
                <Typography weight='regular' type='description'>{availableBalance}</Typography>
            </Td>
            <Td>
                <Typography weight='regular' type='description'>{transitBalance}</Typography>
            </Td>
            <Td>
                <Typography weight='regular' type='description'>{lockedBalance}</Typography>
            </Td>
        </Tr>
    )
}


export default function CollectionsHome() {
    const [myCollections, setMyCollections] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectValue, setSelectValue] = useState("lastweek");
    const [dateRange, setDateRange] = useState(null);
    const todayDateObject = new Date();
    const todayDateString = moment().format('YYYY-MM-DD') + 'T23:59:59';
    const getMyAccounts = async (daterange) => {
        try {
            setLoading(true);
            const { data } = await BalanceService.getCollectionOverview(daterange);
            if (data?.data?.status === false) {
                setMyCollections([])
                return;
            }
            setMyCollections(data?.data?.list || []);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const createDateRange = (currentSelectValue) => {
        const formatDate = (date) => {
            return date.toISOString().split('T')[0];
        }
        const range = {
            "from": "",
            "to": ""
        }

        const prevDate = new Date();
        if (currentSelectValue === "last24") {
            prevDate.setDate(todayDateObject.getDate() - 1);
        } else if (currentSelectValue === "lastweek") {
            prevDate.setDate(todayDateObject.getDate() - 6);
        } if (currentSelectValue === "lastmonth") {
            prevDate.setMonth(todayDateObject.getMonth() - 1);
        }
        range["from"] = formatDate(prevDate);
        range["to"] = todayDateString;
        return range;
    }

    useEffect(() => {
        if (dateRange == null) return;

        getMyAccounts(dateRange);
    }, [dateRange]);

    useEffect(() => {
        setDateRange(createDateRange(selectValue));
    }, [selectValue]);

    const BaseLayout = ({ children }) => {
        return <Box minH={"92vh"} paddingTop="1" bg={"#F2F2F2"}>
            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                    {children}
                </Box>
            </VStack>
        </Box>
    }

    return (
        <BaseLayout>
            <Flex justifyContent="space-between" alignItems="center">
                <Typography type='body' weight='bold' color='primary' >
                    Overview
                </Typography>
                <Select width="auto" value={selectValue} onChange={(e) => setSelectValue(e.target.value)}>
                    <option value='last24'>Last 24Hrs</option>
                    <option value='lastweek'>Last Week</option>
                    <option value='lastmonth'>Last Month</option>
                </Select>
            </Flex>
            {loading ? <>
                <StickyTable maxH="50vh" labels={fiatLabels} upperCaseHeadings={false}>
                    {[1, 2, 3, 4].map(() =>
                        <Tr height={"46px"}>
                            <Td><Skeleton height="25px" paddingRight="72px" /></Td>
                            <Td><Skeleton height="25px" /></Td>
                            <Td><Skeleton height="25px" /></Td>
                            <Td><Skeleton height="25px" /></Td>
                        </Tr>
                    )}
                </StickyTable>
            </> : myCollections.length ? <StickyTable maxH="50vh" labels={fiatLabels} upperCaseHeadings={false}>
                <Tbody>{
                    myCollections?.map((item, index) => (
                        <TableColumn key={index} currency={item.currency} logo={item.logo} availableBalance={`${currencyFormatter(item.invoicedVolume)} ${item.currency}`} transitBalance={`${currencyFormatter(item.totalSettled)} ${item.currency}`} lockedBalance={`${currencyFormatter(item.unsettled)} ${item.currency}`} />
                    ))
                }</Tbody>
            </StickyTable> : <>
                <Flex minH="44vh" justify={'center'} alignItems={'center'} direction={'column'}>
                    <Image src={EmptyIcon} mt="6" />
                    <Text fontSize="18px" mt="5" color="#5F84E2">No data</Text>
                </Flex>
            </>}
        </BaseLayout>
    )
}

import { useField, useFormikContext } from "formik";
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, FormControl, FormErrorMessage, FormLabel, Input, Radio, RadioGroup, Select, Stack, Img, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import { debounce } from 'lodash';
import { createRef, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { FaAsterisk } from "react-icons/fa";

//Custom css and icons
import './Input.css';
import 'react-datepicker/dist/react-datepicker.css';

import Typography from "../Typography";

/**
 * @dev All of these components only works inside a formik form
 */

export const TextField = ({ label, errorStyles, required = true, id, isTouch = true, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  return (
    <FormControl isInvalid={isInvalid} zIndex={0} h={"80px"}>
      <FormLabel display={"flex"} alignItems={"start"} columnGap={1} htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
          {label}
        </Typography>
        {required && <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" />}
      </FormLabel>
      <Input {...field} {...props} />
      <FormErrorMessage {...errorStyles}>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export const RadioField = ({ label, options, isTouch = true, id, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  return (
    <FormControl isInvalid={isInvalid} >
      <FormLabel htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"} >
          {label}
        </Typography>
      </FormLabel>
      <RadioGroup _placeholder={"Select"} {...field} {...props} onChange={val => helpers.setValue(val)} value={field.value} defaultValue={options[0].value}>
        <Stack direction='row' gap={"40px"}>
          {options?.map((option) => (
            <Radio key={option.value} value={option.value}>{option.label}</Radio>
          ))}
        </Stack>
      </RadioGroup>
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export const SelectField = ({ label, id, options, required = false, isTouch = true, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;


  return (
    <FormControl isInvalid={isInvalid} h={"80px"} >
      <FormLabel display={"flex"} alignItems={"start"} columnGap={1} htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
          {label}
        </Typography>
        {required && <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" />}
      </FormLabel>
      <Select {...field} {...props} {...field} onChange={(e) => helpers.setValue(e.target.value)}>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

const RightIcon = ({ isOpen }) => {
  return (
    <Box
      w={"34px"}
      height={"32px"}
      borderRadius={"4px"}
      backgroundColor={"#E8EDFB"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <ChevronDownIcon w={"23px"} h={"23px"}
        transition="transform 0.2s ease-in-out"
        transform={isOpen ? "rotate(-180deg)" : "rotate(0deg)"}

      />
    </Box>
  )
}

export const CustomSelectFieldSearch = ({ label, EmptyMenuLabel, width, id, required = true, options, isTouch = true, selectedOption, setSelectedOption, disabled = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!options || !options.length) return
    setFilteredOptions(options)
  }, [options])


  const handleDebounceFn = useCallback((searchText) => {
    const lowercasedFilter = searchText.toLowerCase();
    const filteredData = options.filter(item =>
      item.label.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredOptions(filteredData);
    setTimeout(() => {
      inputRef.current.focus()
    }, 0);
  }, [options]);

  const debounceFn = useCallback(debounce(handleDebounceFn, 300), [handleDebounceFn]);

  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  const handleSelect = option => {
    setFieldValue(props.name, option.value);
    setSelectedOption(option);
  };

  const handleOptionClick = option => {
    handleSelect(option);
    setFilteredOptions(options);
    inputRef.current.value = '';
  };

  return (
    <FormControl isInvalid={isInvalid} h="80px" w={width || "386px"}>
      <FormLabel display={"flex"} alignItems={"start"} columnGap={1} htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
          {label}
        </Typography>
        {required && <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" />}
      </FormLabel>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton isDisabled={disabled} _hover={{ bg: "#F5F5F5" }} _expanded={{ bg: "#F5F5F5" }}
              h={"45px"} pr={1} pl={4} rightIcon={<RightIcon isOpen={isOpen} />}
              bg={"white"} className="select-menu-panel" borderRadius={"5px"} as={Button} w="full">
              <Box display={"flex"} alignItems={"center"} columnGap={2}>
                {
                  selectedOption?.logo ? <> <Img className="select-menu-option-box-img" boxSize={"1.5rem"} src={process.env.PUBLIC_URL + selectedOption?.logo} alt="logo" />
                    <Typography className="selectBankDropdown" type="description" weight="regular">{selectedOption.label}</Typography>
                  </> : "Select"
                }
              </Box>
            </MenuButton>
            <MenuList padding={"12px"} paddingTop={0} w={width || "386px"} maxH="40vh" overflowY="auto">
              <Box marginBottom={"16px"}>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <SearchIcon color='brand.primary' />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    name="search"
                    id="search-input"
                    ref={inputRef}
                    onChange={(e) => {
                      debounceFn(e.target.value);
                    }}
                  />
                </InputGroup>
              </Box>

              {filteredOptions.length === 0 && (
                <Box>
                  <Typography type="description" weight="regular">{EmptyMenuLabel || "Not Found"}</Typography>
                </Box>
              )}

              {filteredOptions?.map(option => (
                <MenuItem key={(option?.label || "") + (option?.value || "")} paddingY={6} paddingX={2} className="select-menu-option-box" onClick={() => { handleOptionClick(option) }}>
                  <Box display="flex" alignItems="center">
                    <Img width={8} height={8} borderRadius="full" src={process.env.PUBLIC_URL + option.logo} alt="" mr="10px" />
                    <Typography type="description" weight="regular">{option.label}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const CustomSelectField = ({ label, MenuItemWidth, width, id, rightIcon, menuButtonStyles = {}, required = true, options, isTouch = true, selectedOption, setSelectedOption, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setFieldValue } = useFormikContext();

  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  const handleSelect = (option) => {
    setFieldValue(props.name, option.value);
    setSelectedOption(option);
  };

  return (
    <FormControl isInvalid={isInvalid} h={"80px"} w={width || "386px"}>
      <FormLabel display={"flex"} alignItems={"start"} columnGap={1} htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
          {label}
        </Typography>
        {required && <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" />}
      </FormLabel>

      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton style={menuButtonStyles} _hover={{ bg: "#F5F5F5" }} _expanded={{ bg: "#F5F5F5" }}
              h={"45px"} pr={1} pl={2} rightIcon={rightIcon || <RightIcon isOpen={isOpen} />}
              bg={"white"} className="select-menu-panel" borderRadius={"5px"} as={Button} w="full">
              <Box display={"flex"} alignItems={"center"} columnGap={2}>
                {
                  selectedOption?.label ? <> {selectedOption.logo && <Img className="select-menu-option-box-img" boxSize={"1.5rem"} src={process.env.PUBLIC_URL + selectedOption.logo} alt="logo" />}
                    <Typography type="description" weight="regular">{selectedOption?.label?.slice(0, 80)}</Typography>
                  </> : "Select"
                }
              </Box>
            </MenuButton>
            <MenuList px={"12px"} paddingTop={0} w={MenuItemWidth || "386px"} maxH={"35vh"} overflowY={"auto"}>
              {
                options?.length === 0 &&
                <Box>
                  <Typography type="description" weight="regular">Not Found</Typography>
                </Box>
              }

              {options?.map((option) => (
                <MenuItem paddingY={6} paddingX={2} className="select-menu-option-box" key={option.value} onClick={() => handleSelect(option)}>
                  <Box display="flex" alignItems="center">
                    {
                      option?.logo &&
                      <Img width={8} height={8} borderRadius="full" src={process.env.PUBLIC_URL + option.logo} alt="" mr="10px" />
                    }
                    <Typography color="secondary" colorweight="800" type="description" weight="regular">{option?.label}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};


export const SelectMenu = ({ label, id, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <FormControl isInvalid={meta.error && meta.touched} h={"80px"} w={"386px"} zIndex={100}>
      <FormLabel htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"} >
          {label}
        </Typography>
      </FormLabel>
      <Accordion allowMultiple >
        <AccordionItem className="select-menu-option" borderRadius={"5px"}>
          <AccordionButton display={"flex"} h={"40px"} justifyContent={"space-between"} paddingRight={"4px"} >
            <Box className="select-menu-option-box" paddingLeft={"0px"} >
              {
                props.selectedOption?.logo &&
                <Img className="select-menu-option-box-img" src={process.env.PUBLIC_URL + props.selectedOption.logo} alt="logo" />
              }
              {props.selectedOption?.label}
            </Box>
            <Box w={"34px"} height={"32px"} borderRadius={"4px"} backgroundColor={"#E8EDFB"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
              <AccordionIcon w={"23px"} h={"23px"} />
            </Box>
          </AccordionButton>
          <AccordionPanel maxHeight={"40vh"} overflowY={"auto"} className="select-menu-sub-panel" padding={"0px"} >
            <Box className="select-menu-option-box"  >
              <option style={{ paddingLeft: "5px", width: "100%" }} key={""} value={""} onClick={() => {
                setFieldValue(props.name, "");
                props.setSelectedOption({ label: "Select", value: "", logo: "" });
              }}>
                {"Select"}
              </option>
            </Box>
            {options?.map((option) => (
              <Box className="select-menu-option-box" >
                <Img className="select-menu-option-box-img" src={process.env.PUBLIC_URL + option?.logo} alt="logo" />
                <option style={{ width: "100%" }} key={option.value} value={option.value} onClick={() => {
                  setFieldValue(props.name, option.value);
                  props.setSelectedOption(option);
                }}>
                  {option.label}
                </option>
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}

export const DateRangePicker = ({ dateRange, setDateRange, placeholder,  }) => {
  const iconCalendar = <svg display={"block"} width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.33333 3.66667V1M9.66667 3.66667V1M3.66667 6.33333H10.3333M2.33333 13H11.6667C12.0203 13 12.3594 12.8595 12.6095 12.6095C12.8595 12.3594 13 12.0203 13 11.6667V3.66667C13 3.31304 12.8595 2.97391 12.6095 2.72386C12.3594 2.47381 12.0203 2.33333 11.6667 2.33333H2.33333C1.97971 2.33333 1.64057 2.47381 1.39052 2.72386C1.14048 2.97391 1 3.31304 1 3.66667V11.6667C1 12.0203 1.14048 12.3594 1.39052 12.6095C1.64057 12.8595 1.97971 13 2.33333 13Z" stroke="#525C76" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return <>
      <InputGroup>
        <Input fontSize={"15px"} cursor={"default"} ref={ref} value={value} readOnly placeholder={placeholder} />
        <InputRightElement
          cursor="pointer"
          onClick={onClick}
          bg="#E8EDFB"
          width={"unset"}
          height={"unset"}
          margin="4px 10px 0px 0px"
          p="8px"
          rounded={4}
        >
          <Icon as={() => iconCalendar} />
        </InputRightElement>
      </InputGroup>
    </>
  });

  return <DatePicker
    customInput={<CustomInput />}
    selectsRange startDate={dateRange?.[0]}
    endDate={dateRange?.[1]}
    onChange={setDateRange}
    dateFormat={"dd/MM/yyyy"}
    maxDate={new Date()}
  />
}

export const DatePickerField = ({ label, id, isTouch = true, options, isMandatory = true, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const { selectedDate, setSelectedDate, placeholderText } = props;
  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  const calendarRef = createRef(null);

  const toggle = () => {
    calendarRef.current.setOpen(true);
  }

  return (
    <FormControl isInvalid={isInvalid} h={"80px"} >
      <FormLabel htmlFor={id} display={"flex"} alignItems={"start"} columnGap={1} >
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"} >
          {label}
        </Typography>
        {isMandatory &&
          <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" />
        }
      </FormLabel>
      <Box className={meta.error && meta.touched ? "datepicker-wrapper-error" : "datepicker-wrapper"}>
        <DatePicker
          className={"datepicker"}
          {...field}
          {...props}
          dateFormat="dd/MM/yyyy"
          showYearDropdown={true}
          showMonthDropdown={true}
          dropdownMode="select"
          ref={calendarRef}
          selected={(field.value && new Date(field.value)) || null}
          maxDate={new Date()}
          onChange={val => {
            const dateInString = val?.toLocaleDateString('en-GB') || "";
            setFieldValue(field.name, val);
            setSelectedDate({ label: dateInString, value: dateInString });
          }}
        />
        <Box w={"34px"} h={"32px"} backgroundColor={"#E8EDFB"} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={"4px"} marginTop={"4px"} marginRight={"5px"} onClick={() => toggle()} >
          <CiCalendar className="datepicker-icon" />
        </Box>
      </Box>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl >
  );
}

export const StaticTextField = ({ label, id, required = false, field, ...props }) => {
  return (
    <FormControl zIndex={0} h={"80px"}>
      <FormLabel display={"flex"} alignItems={"start"} columnGap={1} htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
          {label}
        </Typography>
        {required && <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" />}
      </FormLabel>
      <Input {...field} {...props} />
    </FormControl>
  );
};

export const InputControl = ({ control, isTouch, label, id, options, ...props }) => {
  // const [field, meta, helpers] = useField(props);
  if (control === 'select') {
    return (
      <SelectField isTouch={isTouch} label={label} id={id} options={options} {...props} />
    )
  }
  if (control === 'radio') {
    return (
      <RadioField isTouch={isTouch} label={label} id={id} options={options} {...props} />
    )
  }
  if (control === 'date') {
    return (
      <TextField type="date" isTouch={isTouch} label={label} id={id} {...props} />
    )
  }
  return (
    <TextField isTouch={isTouch} label={label} id={id} {...props} />
  )
}
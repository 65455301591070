import React from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import { useSelector } from "react-redux";

import CryptoCheckout from "../modules/payout/CryptoCheckout";
import CryptoProcessing from "../modules/payout/CryptoProcessing";
import PayoutOrders from "../modules/order/payout/PayoutOrders";
import PayoutOrderSummary from "../modules/order/payout/PayoutOrderSummary";
import PayOrdersV2 from "../modules/order/pay/PayOrdersV2";
import PayinOrdersV2 from "../modules/order/payin/PayinOrdersV2";
import PayoutOrdersV2 from "../modules/order/payout/PayoutOrdersV2";
import Payin from "../modules/payin/Payin";
import SettlementDashboard from "../modules/balances/settlements/SettlementDashboard";
import PayinSettlement from "../modules/balances/settlements/payin/PayinSettlement";
import Contact from "../modules/contact/Contact";
import ProfileBadge from "../components/nav/ProfileBadge";
import AddContact from "../modules/contact/add-contact/AddContact";
import EditContact from "../modules/contact/edit-contact";
import Settings from "../modules/settings/Settings";
import SendMoney from "../modules/send-money/SendMoney";
import IndividualPayment from "../modules/send-money/individual-payments/IndividualPayment";
import MyAccounts from "../modules/my-account";
import Prefund from "../modules/my-account/Prefund/Prefund";
import PrefuncSuccess from "../modules/my-account/Prefund/Success";
import CollectMoney from "../modules/collect-money";
import Customer from "../modules/customers/Customer";
import AddCustomer from "../modules/customers/AddCustomer";
import EditCustomer from "../modules/customers/EditCustomer";
import CollectionSummary from "../modules/collect-money/Summary";
import Dashboard from "../modules/dashboard/Dashboard";
import OrderDashboard from "../modules/order/OrderDashboard";
import PayOrderSummary from "../modules/order/pay/PayOrderSummary";
import PayinOrders from "../modules/order/payin/PayinOrders";
import Transaction from "../modules/transaction/Transaction";
import CreateOrder from "../modules/payin/CreateOrder";
import Recipients from "../modules/recipients/Recipients";
import PaymentMethods from "../modules/payin/PaymentMethods";
import ConfirmOrder from "../modules/payin/ConfirmOrder";
import PaymentProcessing from "../modules/payin/PaymentProcessing";
import CreatePayoutOrder from "../modules/payout/CreatePayoutOrder";
import ConfirmPayoutOrder from "../modules/payout/ConfirmOrder";
import PaymentCheckout from "../modules/payin/PaymentCheckout";
import PaymentSuccess from "../modules/payin/PaymentSuccess";
import PayinOrderSummary from "../modules/order/payin/PayinOrderSummary";
import TransactionSummary from "../modules/transaction/TransactionSummary";
import CollectionsHome from "../modules/collections";
import Approval from "../modules/approvals/Approval";
import ApprovalSummary from "../modules/approvals/ApprovalSummary";
import CollectionApprovalSummary from "../modules/approvals/CollectionApprovalSummary";
import CollectionTransactions from "../modules/transaction/collection/CollectionTransactions";
import CollectionTransactionSummary from "../modules/transaction/collection/CollectionTransactionSummary/CollectionTransactionSummary";
import CustomerHome from "../modules/collections-customers/home/Home";
import CustomerTransactions from "../modules/collections-customers/transactions/CustomerTransactions";
import MakePayment from "../modules/collections-customers/home/MakePayment/MakePayment";
import CompleteKyb from "../modules/collections-customers/home/KybFlow/CompleteKyb";
import Summary from "../modules/collections-customers/home/MakePayment/Summary";
import HomeCombined from "../modules/home/HomeCombined";
import ContactCombined from "../modules/contact/ContactCombined";

const AdminRoutes = (props) => {
  const { PageLayout } = props;
  return (
    <div style={{ marginLeft: "252px" }}>
      <div style={{ marginRight: "10px" }}>
        <Routes>
          <Route path="*" element={<Navigate to="/order" />} />
          <Route
            path="/payin"
            element={
              <PageLayout title={"Pay in currency,  receive crypto."}>
                <Payin />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/create-order"
            element={
              <PageLayout title={"Pay in currency,  receive crypto."}>
                <CreateOrder />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/payment-methods"
            element={
              <PageLayout title="Payment Methods">
                <PaymentMethods />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/confirm-order"
            element={
              <PageLayout title="Confirm Order">
                <ConfirmOrder />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/confirm-order/checkout/"
            element={
              <PageLayout title="Checkout">
                <PaymentCheckout />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/payment-status"
            element={
              <PageLayout title="Payment Processing">
                <PaymentProcessing />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/payment-summary"
            element={
              <PageLayout title="Payment Summary">
                <PaymentSuccess />
              </PageLayout>
            }
          ></Route>
          {/* <Route
                            exact path="/payout"
                            element={
                                <PageLayout title={"Payout"}>
                                    <Payout />
                                </PageLayout>
                            }
                        ></Route>
                        <Route
                            path="/payout/create-order"
                            element={
                                <PageLayout title={"Pay in crypto, Send in fiat"}>
                                    <CreatePayoutOrder />
                                </PageLayout>
                            }
                        ></Route>
                        <Route
                            path="/payout/confirm-order"
                            element={
                                <PageLayout title={"Confirm your Order"}>
                                    <ConfirmPayoutOrder />
                                </PageLayout>
                            }
                        ></Route>
                        <Route
                            path="/payout/crypto-checkout"
                            element={
                                <PageLayout title="Payout">
                                    <CryptoCheckout />
                                </PageLayout>
                            }
                        ></Route>
                        <Route
                            path="/payout/crypto-status"
                            element={
                                <PageLayout title="Payment Processing">
                                    <CryptoProcessing />
                                </PageLayout>
                            }
                        ></Route>
                        <Route
                            path="/recipients"
                            element={
                                <PageLayout title="Recipients">
                                    <Recipients />
                                </PageLayout>
                            }
                        ></Route> */}
          <Route
            path="/order"
            element={
              <PageLayout title={""}>
                <OrderDashboard />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-in-order"
            element={
              <PageLayout title={"PAYOUT (FIAT TO STABLECOIN)"}>
                <PayinOrders />
              </PageLayout>
            }
          ></Route>
          <Route
            exact
            path="/order/pay-out-order"
            element={
              <PageLayout title={"PAYOUT (STABLECOIN TO FIAT)"}>
                <PayoutOrdersV2 />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-order"
            element={
              <PageLayout title={"Pay Orders"}>
                <PayOrdersV2 />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-order/:orderId"
            element={
              <PageLayout title={"Order Summary"}>
                <PayOrderSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-in-order/:orderId"
            element={
              <PageLayout title={"PayIn Order Summary"}>
                <PayinOrderSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-out-order/:orderId"
            element={
              <PageLayout title={"PayOut Order Summary"}>
                <PayoutOrderSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <PageLayout title={"Settings"}>
                <Settings />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/balances/settlement"
            element={
              <PageLayout title={"Settlements"}>
                <SettlementDashboard />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/balances/settlement/payin"
            element={
              <PageLayout title={"Payout Settlements (FIAT TO STABLECOIN)"}>
                <PayinSettlement />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/collection"
            element={
              <PageLayout title={"Collect Money"}>
                <CollectMoney />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/collection/summary"
            element={
              <PageLayout title={"Summary"}>
                <CollectionTransactionSummary />
              </PageLayout>
            }
          ></Route>
          {/* <Route
                            path="/transactions"
                            element={
                                <PageLayout title={"Transaction History"}>
                                    <Transaction />
                                </PageLayout>
                            }
                        ></Route> */}
          <Route
            path="/transactions"
            element={
              <PageLayout title={"Collection Transactions"}>
                <CollectionTransactions />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/transactions/:orderId"
            element={
              <PageLayout title={"Collection Transactions"}>
                <CollectionTransactionSummary />
              </PageLayout>
            }
          ></Route>
          {/* <Route
                                path="/help"
                                element={
                                    <PageLayout title={"Help Center"}>
                                    <p>We can't help.</p>
                                    </PageLayout>
                                }
                            ></Route> */}

          <Route
            path="/contact"
            element={
              <PageLayout title={"Contact"}>
                <Contact />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/contact/add"
            element={
              <PageLayout title={"Contact"}>
                <AddContact />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/contact/edit/:contactId"
            element={
              <PageLayout title={"Edit Contact"}>
                <EditContact />
              </PageLayout>
            }
          ></Route>

          <Route
            path="/payout-transactions"
            element={
              <PageLayout title={"Transaction"}>
                <Transaction />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payout-transactions/summary/:orderId"
            element={
              <PageLayout title={"Transaction History"}>
                <TransactionSummary />
              </PageLayout>
            }
          ></Route>

          <Route
            path="/send-money"
            element={
              <PageLayout title={"Send Money"}>
                <SendMoney />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/send-money/individual-payment"
            element={
              <PageLayout>
                <IndividualPayment />
              </PageLayout>
            }
          ></Route>

          {/* My Accounts Routes */}
          <Route
            path="/my-accounts"
            element={
              <PageLayout title={"My Accounts"}>
                <MyAccounts />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/my-accounts/add-money"
            element={
              <PageLayout title={"Add Money"}>
                <Prefund />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/my-accounts/add-money/success"
            element={
              <PageLayout title={"Add Money"}>
                <PrefuncSuccess />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/customers"
            element={
              <PageLayout title={"Customers"}>
                <Customer />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/customers/add-customer"
            element={
              <PageLayout title={"Add Customer"}>
                <AddCustomer />
              </PageLayout>
            }
          ></Route>
          <Route
            path="customers/update-customer"
            element={
              <PageLayout title={"Update Customer"}>
                <EditCustomer />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/approvals"
            element={
              <PageLayout title={"Approvals"}>
                <Approval />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/approvals/summary"
            element={
              <PageLayout title={"Approvals"}>
                <ApprovalSummary />
              </PageLayout>
            }
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

const DefaultRoutes = (props) => {
  const { PageLayout } = props;
  return (
    <div style={{ marginLeft: "252px" }}>
      <div style={{ marginRight: "10px" }}>
        <Routes>
          <Route path="*" element={<Navigate to="/order" />} />
          <Route
            path="/order"
            element={
              <PageLayout title={""}>
                <OrderDashboard />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-in-order"
            element={
              <PageLayout title={"PAYOUT (FIAT TO STABLECOIN)"}>
                <PayinOrders />
              </PageLayout>
            }
          ></Route>
          <Route
            exact
            path="/order/pay-out-order"
            element={
              <PageLayout title={"PAYOUT (STABLECOIN TO FIAT)"}>
                <PayoutOrdersV2 />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-in-order/:orderId"
            element={
              <PageLayout title={"PayIn Order Summary"}>
                <PayinOrderSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-out-order/:orderId"
            element={
              <PageLayout title={"PayOut Order Summary"}>
                <PayoutOrderSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/balances/settlement"
            element={
              <PageLayout title={"Balances"}>
                <SettlementDashboard />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/balances/settlement/payin"
            element={
              <PageLayout title={"Collection Balances (FIAT TO STABLECOIN)"}>
                <PayinSettlement />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <PageLayout title={"Settings"}>
                <Settings />
              </PageLayout>
            }
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

const InvoiceRoutes = (props) => {
  const { PageLayout } = props;
  return (
    <div style={{ marginLeft: "252px" }}>
      <div style={{ marginRight: "10px" }}>
        <Routes>
          <Route path="*" element={<Navigate to="/order" />} />
          <Route
            path="/payin"
            element={
              <PageLayout title={"Pay in currency,  receive crypto."}>
                <Payin />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/create-order"
            element={
              <PageLayout title={"Pay in currency,  receive crypto."}>
                <CreateOrder />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/payment-methods"
            element={
              <PageLayout title="Payment Methods">
                <PaymentMethods />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/confirm-order"
            element={
              <PageLayout title="Confirm Order">
                <ConfirmOrder />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/confirm-order/checkout/"
            element={
              <PageLayout title="Checkout">
                <PaymentCheckout />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/payment-status"
            element={
              <PageLayout title="Payment Processing">
                <PaymentProcessing />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payin/payment-summary"
            element={
              <PageLayout title="Payment Summary">
                <PaymentSuccess />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order"
            element={
              <PageLayout title={""}>
                <OrderDashboard />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-in-order"
            element={
              <PageLayout title={"PAYOUT (FIAT TO STABLECOIN)"}>
                <PayinOrders />
              </PageLayout>
            }
          ></Route>

          <Route
            path="/order/pay-in-order/:orderId"
            element={
              <PageLayout title={"PayIn Order Summary"}>
                <PayinOrderSummary />
              </PageLayout>
            }
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

const EcomRoutes = (props) => {
  const { PageLayout } = props;
  return (
    <div style={{ marginLeft: "252px" }}>
      <div style={{ marginRight: "10px" }}>
        <Routes>
          <Route path="*" element={<Navigate to="/order" />} />
          <Route
            path="/order"
            element={
              <PageLayout title={""}>
                <OrderDashboard />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/order/pay-in-order"
            element={
              <PageLayout title={"PAYOUT (FIAT TO STABLECOIN)"}>
                <PayinOrders />
              </PageLayout>
            }
          ></Route>

          <Route
            path="/order/pay-in-order/:orderId"
            element={
              <PageLayout title={"PayIn Order Summary"}>
                <PayinOrderSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <PageLayout title={"Settings"}>
                <Settings />
              </PageLayout>
            }
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

const CustomerRoutes = (props) => {
  const { PageLayout } = props;

  return (
    <div style={{ marginLeft: "252px" }}>
      <div style={{ marginRight: "10px" }}>
        <Routes>
          <Route path="*" element={<Navigate to="/customer/home" />} />
          <Route
            path="/customer/home"
            element={
              <PageLayout title={""}>
                <CustomerHome />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/customer/home/make-payment"
            element={
              <PageLayout title={""}>
                <MakePayment />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/customer/transactions"
            element={
              <PageLayout title={""}>
                <CustomerTransactions />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/customer/home/kyb"
            element={
              <PageLayout title={""}>
                <CompleteKyb />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/customer/home/make-payment/summary"
            element={
              <PageLayout title={""}>
                <Summary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <PageLayout title={"Settings"}>
                <Settings />
              </PageLayout>
            }
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

const PayoutAndCollectionRoutes = (props) => {
  const { PageLayout } = props;
  return (
    <div style={{ marginLeft: "252px" }}>
      <div style={{ marginRight: "10px" }}>
        <Routes>
          <Route path="*" element={<Navigate to="/home" />} />
          <Route
            path="/home"
            element={
              <PageLayout title={"Welcome to TransFi"}>
                <HomeCombined />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/contact"
            element={
              <PageLayout title={"Contact"}>
                <ContactCombined />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/contact/add"
            element={
              <PageLayout title={"Contact"}>
                <AddContact />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/contact/edit/:contactId"
            element={
              <PageLayout title={"Edit Contact"}>
                <EditContact />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/transactions"
            element={
              <PageLayout title={"Collection Transactions"}>
                <CollectionTransactions />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/transactions/:orderId"
            element={
              <PageLayout title={"Collection Transactions"}>
                <CollectionTransactionSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payout-transactions"
            element={
              <PageLayout title={"Transaction"}>
                <Transaction />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/payout-transactions/summary/:orderId"
            element={
              <PageLayout title={"Transaction History"}>
                <TransactionSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/approvals"
            element={
              <PageLayout title={"Approvals"}>
                <Approval />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/approvals/summary"
            element={
              <PageLayout title={"Approvals"}>
                <ApprovalSummary />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/my-accounts"
            element={
              <PageLayout title={"My Accounts"}>
                <MyAccounts />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/my-accounts/add-money"
            element={
              <PageLayout title={"Add Money"}>
                <Prefund />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/my-accounts/add-money/success"
            element={
              <PageLayout title={"Add Money"}>
                <PrefuncSuccess />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/send-money"
            element={
              <PageLayout title={"Send Money"}>
                <SendMoney />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/send-money/individual-payment"
            element={
              <PageLayout>
                <IndividualPayment />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/collection"
            element={
              <PageLayout title={"Collect Money"}>
                <CollectMoney />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/collection/summary"
            element={
              <PageLayout title={"Summary"}>
                <CollectionSummary />
              </PageLayout>
            }
          ></Route>
          {/* <Route
            path="/customers"
            element={
              <PageLayout title={"Customers"}>
                <Customer />
              </PageLayout>
            }
          ></Route> */}
          <Route
            path="/customers/add-customer"
            element={
              <PageLayout title={"Add Customer"}>
                <AddCustomer />
              </PageLayout>
            }
          ></Route>
          <Route
            path="customers/update-customer"
            element={
              <PageLayout title={"Update Customer"}>
                <EditCustomer />
              </PageLayout>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <PageLayout title={"Settings"}>
                <Settings />
              </PageLayout>
            }
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

const RouteSelector = (props) => {
  const { PageLayout } = props;
  const orgType = useSelector(
    (state) => state?.login?.orgDetails?.orgType || "default"
  );
  const customerType = useSelector(
    (state) => state?.login?.userDetails?.cxRoles || []
  )[0];

  const routeMap = {
    admin: <AdminRoutes PageLayout={PageLayout} />,
    default: <DefaultRoutes PageLayout={PageLayout} />,
    payout: <PayoutAndCollectionRoutes PageLayout={PageLayout} />,
    collections: <PayoutAndCollectionRoutes PageLayout={PageLayout} />,
    invoice: <InvoiceRoutes PageLayout={PageLayout} />,
    ecom: <EcomRoutes PageLayout={PageLayout} />,
    guest: <CustomerRoutes PageLayout={PageLayout} />,
  };

  return (
    <>{customerType === "guest" ? routeMap[customerType] : routeMap[orgType]}</>
  );
};

export default RouteSelector;

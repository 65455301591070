import React, { useEffect, useState } from 'react'
import { Box, Flex, Icon, Spacer, Skeleton, Img, Divider } from '@chakra-ui/react'
import Typography from '../../../../components/Common/Typography'
import { useNavigate, useParams } from 'react-router-dom'
import { GoArrowLeft } from "react-icons/go";
import { OrderDetailsColumn } from '../../../my-account/Prefund/WalletToWalletSuccess'
import MainCard, { CardWithTitle } from '../../../../components/Common/MainCard'
import { currencyFormatter } from '../../../../utils/formatter'
import OrderService from '../../../../services/createOrderService';
import CollectionStepper from './CollectionStepper'
import { iconPayin, StatusBadge } from '../../Transaction';

export const IconLabel = ({ currency, label, icon }) => {
    return (
        <Flex columnGap={2} alignItems={"center"}>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} bg={"#FFF4ED"} borderRadius={"100%"} width={"36px"} height={"36px"}>
                {<Icon as={icon} size={25} color='#FFC9A7' />}
            </Box>

            <Flex direction={"column"}>
                <Typography type='body'>
                    {label}
                </Typography>
                <Typography textAlign="left" weight='regular' type='caption' color='secondary'>
                    {currency}
                </Typography>
            </Flex>
        </Flex>
    )
}

export default function CollectionTransactionSummary() {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [summary, setSummary] = useState({});
    const [loading, setLoading] = useState(true)
    const [lastStatus, setLastStatus] = useState(null);


    const getOrderSummary = async () => {
        try {
            const response = await OrderService.getCollectionOrderSummary({ orderId });
            if (response.data.success) {
                const data = response.data.data;
                setSummary(data);
            }
        } catch (error) {
        } finally {

            setLoading(false)
        }
    };

    const checkOrderStatus = async () => {
        try {
            const res = await OrderService.getOrderStatus({ orderId });
            const currentStatus = res?.data?.data?.status;
            if (currentStatus !== lastStatus) {
                setLastStatus(currentStatus);  // Update the last status
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        const initFetch = async () => {
            // Initial check to set status and fetch summary once
            const res = await OrderService.getOrderStatus({ orderId });
            const initialStatus = res?.data?.data?.status;
            setLastStatus(initialStatus);  // Set the initial status
            // await getOrderSummary();  // Initial detailed fetch based on the first status
        };

        initFetch();

        let intervalId = setInterval(checkOrderStatus, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [orderId]); // Dependencies only include orderId

    // Effect to handle when status changes
    useEffect(() => {
        if (lastStatus !== null) {
            getOrderSummary();  // Call this function whenever lastStatus changes
        }
    }, [lastStatus]); // Depend on lastStatus

    if (loading) return (
        <Box minH={"92vh"} p={"10px 40px"} bg={"#F2F2F2"}>
            <Flex w={"fit-content"} cursor={"pointer"} onClick={() => navigate(-1)} columnGap={2} alignItems={"center"}>
                <GoArrowLeft size={"13px"} />
                <Typography type="body">
                    Back
                </Typography>
            </Flex>
            <Typography type='largeheader' weight='bold' >
                Transaction
            </Typography>
            <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} >
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"756px"}>
                    <Skeleton height={"100px"} w={"100%"} />
                    <Skeleton height={"150px"} w={"100%"} />
                    <Skeleton height={"350px"} w={"100%"} />
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box minH={"92vh"} p={"10px 40px"} bg={"#F2F2F2"}>
            <Flex w={"fit-content"} cursor={"pointer"} onClick={() => navigate(-1)} columnGap={2} alignItems={"center"}>
                <GoArrowLeft size={"13px"} />
                <Typography type="body">
                    Back
                </Typography>
            </Flex>

            <Typography type='largeheader' weight='bold' >
                Transactions
            </Typography>

            <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"800px"}>
                    <Flex mb={3} width={"100%"} flex={1} justifyContent={"space-between"} pr={3}>
                        <Flex columnGap={2} alignItems={"center"}>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={"100%"} width={"45px"} height={"45px"}>
                                {<Icon as={() => iconPayin} />}
                            </Box>

                            <Flex direction={"column"}>
                                <Typography textAlign="left" weight='bold'>
                                    Collect from {summary?.senderName?.firstName}
                                </Typography>
                                <Typography textAlign="left" weight='regular' type='body' color='secondary'>
                                    Txn Id - {summary?.orderId}
                                </Typography>
                            </Flex>
                        </Flex>
                        <Flex alignItems={"center"} gap={1}>
                            <StatusBadge status={summary?.status} type="collections" />
                        </Flex>

                    </Flex>
                    <Box w={"100%"}>
                        <MainCard isBorder={true}>
                            <CollectionStepper data={summary} />
                        </MainCard>
                        <Spacer h={19} />
                        <CardWithTitle isBorder={true} title={"Order Details"}>
                            <Flex flexDir={"column"}>
                                {summary?.referenceId && <OrderDetailsColumn label="Reference Id" value={summary?.referenceId} />}
                                <OrderDetailsColumn label="Transaction Id" value={summary?.orderId} />
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
                                    <Typography weight='medium' type='description' >
                                        Customer
                                    </Typography>
                                    <Flex textAlign={"right"} direction={"column"} gap="0px" w='fit-content' bg='white' p='10px' columnGap={2} borderRadius='5px' >
                                        <Flex alignItems={"center"} justifyContent={"end"} columnGap={0.5}>
                                            <Img src={process.env.PUBLIC_URL + summary?.additionalDetails?.countryLogo} alt='contactLogo' w={"25px"} h={"18px"} />
                                            <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                                                {summary?.senderName?.firstName}
                                            </Typography>
                                        </Flex>
                                        <Typography type='caption' weight='regular' color='secondary' colorweight="400" >
                                            {summary?.email}
                                        </Typography>
                                    </Flex>
                                </Box>
                            </Flex>

                            <Divider my={"20px"} borderWidth={"1px"} />

                            <Flex flexDir={"column"}>
                                {summary?.additionalDetails?.invoiceUrl && <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
                                    <Typography weight='medium' type='description' >
                                        Invoice
                                    </Typography>
                                    <Flex direction={"column"} gap="0px" w='fit-content' bg='white' p='10px' columnGap={2} borderRadius='5px' >
                                        <a target="_blank" rel="noopener noreferrer" href={summary?.additionalDetails?.invoiceUrl} >
                                            <Typography textDecoration={"underline"} textAlign={"right"} type='caption' weight='regular' color='primary' colorweight="500" >
                                                View Invoice
                                            </Typography>
                                        </a>
                                    </Flex>
                                </Box>}
                                <OrderDetailsColumn label="Invoice amount" value={`${currencyFormatter(summary?.depositAmount)} ${summary?.depositCurrency}`} />
                                <OrderDetailsColumn label="Processing fee" value={`${currencyFormatter(Number(summary?.fiat?.processingFee))} ${summary?.depositCurrency}`} />
                                <OrderDetailsColumn label="Amount to be received" value={`${currencyFormatter(summary?.depositAmount - Number(summary?.fiat?.processingFee))} ${summary?.depositCurrency}`} />
                            </Flex>
                        </CardWithTitle>
                        <Spacer h={19} />
                        <CardWithTitle padding={"20px"} isBorder={true} title={"Additional Details"}>
                            <Flex flexDir={"column"}>
                                {/* <OrderDetailsColumn label="Link Validity" value={""} /> */}
                                <OrderDetailsColumn label="Payment purpose" value={summary?.purposeCode} />
                            </Flex>
                        </CardWithTitle>
                    </Box>
                </Box>
            </Box>

        </Box>

    )
}

import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Flex, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text, Button, Divider, Center } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { ChevronDownIcon, QuestionOutlineIcon, ChevronUpIcon } from "@chakra-ui/icons";

import SideNavBar from "../components/nav";
import { signOutUser } from "../store/slices/loginSlices";
import ProfileBadge from "../components/nav/ProfileBadge";
import RouteSelector from "./RouteSelector";

const PageLayout = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state?.login?.userDetails)
    const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];
    const { orgName = '' } = useSelector((state) => state?.login?.orgDetails) || {};
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
    const companyName = orgName.charAt(0).toUpperCase() + orgName.slice(1);
    const [select, setSelect] = useState(false);

    let canGoBack = false;
    let currHistoryIdx;
    if (typeof window !== undefined) {
        currHistoryIdx = window?.history?.state?.idx || 0;
        canGoBack =
            location.pathname !== "/" &&
            location.pathname !== "/dashboard" &&
            location.pathname !== "/payin" &&
            location.pathname !== "/payout" &&
            location.pathname !== "/recipients" &&
            location.pathname !== "/order" &&
            location.pathname !== "/transactions" &&
            location.pathname !== "/help" &&
            location.pathname !== '/payin/payment-status' &&
            location.pathname !== '/payin/payment-summary' &&
            currHistoryIdx != 0;
    }

    const signOutAccount = useCallback(() => {
        dispatch(signOutUser());
    }, [dispatch]);

    return (
        <div style={{ position: 'relative' }}>
            <Flex
                alignItems={"center"}
                height={"68px"}
                justifyContent={"flex-end"}
                p="22px"
                borderBottom={"1px"}
                borderBottomColor={"#E4E4E4"}
                position={'sticky'}
                top={0}
                zIndex={'100'}
                bg={'#FFFFFF'}
            >
                {/* <Box>
                    <HStack>
                        {canGoBack ? (
                            <Icon
                                as={IoMdArrowBack}
                                width={"24px"}
                                height={"24px"}
                                onClick={() => navigate(-1)}
                                cursor="pointer"
                            />
                        ) : null}
                        <Heading as="h4" size="md">
                            {props?.title}
                        </Heading>
                    </HStack>
                </Box> */}
                {/* <Icon as={IoIosNotificationsOutline} width={"24px"} height={"24px"} /> */}
                <Flex gap={8} alignItems={'center'}>
                    {(location.pathname != "/home" && ["payout", "collections"].includes(orgType)) && <div>
                        <Button
                            bg={"white"}
                            border="1px solid"
                            borderColor="#DEDEDE"
                            borderRadius="25px 0 0 25px"
                            h="42"
                            onClick={() => navigate('/send-money')}
                        >Send Money</Button>
                        {customerType !== 'guest' && <Button
                            bg="white"
                            color="black"
                            border="1px solid"
                            borderColor="#DEDEDE"
                            borderRadius="0 25px 25px 0"
                            h="42"
                            onClick={() => navigate('/collection')}
                        >Collect Money</Button>}
                    </div>}
                    {/* <Center height='50px'>
                        <Divider orientation='vertical' size={"lg"} />
                    </Center> */}
                    <Flex justify={'center'} alignItems={'center'} p={'10px'} rounded={'6px'}
                        _hover={{ bg: select ? "" : '#F2F2F2' }} w={'153px'} h={'56px'}>
                        <Menu >
                            <MenuButton onClick={() => setSelect(!select)}>
                                <HStack >
                                    <ProfileBadge />
                                    <Flex direction={'column'} alignItems={'start'}>
                                        <Text>{userDetails?.firstName || (userDetails?.name && userDetails?.name?.split(" ")[0]) || ""}</Text>
                                        {userDetails?.cxRoles?.map((role, i) =>
                                            <Text key={i} color={'#B3B3B3'} fontSize={'12px'} fontWeight={'400'}>{role}</Text>)}
                                    </Flex>
                                    {select ? <Icon width={'20px'} height={'20px'} as={ChevronUpIcon} color={"#1A4FD6"} /> :
                                        <Icon width={'20px'} height={'20px'} as={ChevronDownIcon} color={"#1A4FD6"} />}
                                </HStack>
                            </MenuButton>
                            <MenuList w={'153px'} p={'0'}>
                                <Flex alignItems={'center'} h={'25px'} bg={"#F2F2F2"}>
                                    <Text ml={'12px'} fontSize={'14px'} fontWeight={"400"} color={"#B3B3B3"}>
                                        {companyName}
                                    </Text>
                                </Flex>
                                <Link to='/settings'>
                                    <MenuItem h={'38px'} borderBottom={'1px'} borderBottomColor={'#F2F2F2'} textColor={"#808080"}>
                                        Settings
                                    </MenuItem>
                                </Link>
                                <MenuItem h={'38px'} onClick={signOutAccount} color={"#1A4FD6"} fontWeight={'400'}>Log out</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Flex>

            </Flex>
            <Flex height={"full"} align={{ base: "flex-start", md: "center" }}>
                <Container
                    p="0"
                    maxW={{ base: "100%" }}
                    minH={{ base: "100%", md: "max(90vh);" }}
                    height="auto"
                    borderRadius={{ base: "none", md: "sm" }}
                    display="flex"
                    flexDirection="column"
                    position="relative"
                >
                    {props.children}
                </Container>
            </Flex>
        </div>
    );
};

export default function AuthorizedRoutes(props) {

    return (
        <>
            <SideNavBar />
            <RouteSelector PageLayout={PageLayout} />
        </>
    );
}

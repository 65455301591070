import React, { useEffect, useState } from 'react';
import { Box, Center, Grid, GridItem } from '@chakra-ui/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from '@chakra-ui/image';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';

import Typography from '../../../components/Common/Typography';
import { setSelectedBank } from '../../../store/slices/AddMoneySlice';
import PaymentMethodsService from '../../../services/paymentMethodService';
import { useField } from 'formik';

const PaymentMethods = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const fiatTicker = useSelector((state) => state.addMoney?.currencyToPay);
    const [paymentMethods, setPaymentMethods] = useState([])
    const selectedBank = useSelector((state) => state.addMoney?.selectedBank);
    const [prevFiatTicker, setPrevFiatTicker] = useState(null);
    const [amountField] = useField('amount')

    const handlePaymentSelect = (method) => {
        dispatch(setSelectedBank({
            value: method.paymentCode,
            label: method.name,
            logo: method.icon,
            limit: {
                min: method.minAmount,
                max: method.maxAmount
            }
        }));
    };


    const fetchPaymentMethods = async (currency,amount) => {
        setLoading(true)
        try {
            const resp = await PaymentMethodsService.getPaymentMethodList({ currency,amount });
            const methods = resp.data?.data?.paymentMethods || [];
            const banks = methods?.filter((item, index, self) =>
                index === self.findIndex((t) => (['bank_transfer', 'sepa_bank', 'sepa_bank_va', 'netbanking', 'local_wallet'].includes(t.paymentType) && t.paymentCode === item.paymentCode))
            );
        
            setPrevFiatTicker(fiatTicker.symbol);
            setPaymentMethods(banks || []);

        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!fiatTicker) return;
        if(fiatTicker.symbol === "EUR") {
            fetchPaymentMethods(fiatTicker?.symbol, amountField.value)
        }
        if (fiatTicker?.symbol !== prevFiatTicker) {
            fetchPaymentMethods(fiatTicker?.symbol)
        }
    }, [fiatTicker, prevFiatTicker]);


    return (
        <Box bg='white' w='100%' p={"20px 40px"} borderRadius={"9px"} mt={5}>
            <Typography pb={8} type='body' weight='medium' color='primary' >
                Payment Method
            </Typography>

            {
                !loading && paymentMethods.length === 0 && (
                    <Typography type={'description'} weight={'regular'} color={'secondary'}>No payment methods available</Typography>
                )

            }

            <Grid templateColumns='repeat(4, 1fr)' gap={8}>
                {loading && (
                    [1, 2, 3, 4].map((_, index) => (
                        <GridItem key={index}>
                            <Center display={'flex'} flexDirection={'column'} justify={'center'} alignItems={'center'}>
                                <SkeletonCircle width={"80px"} height={"80px"} />
                                <Skeleton mt={4} height="20px" width="100px" />
                            </Center>
                        </GridItem>
                    )))}


                {loading === false && paymentMethods.map((bank, index) =>
                    <GridItem key={index} onClick={() => handlePaymentSelect(bank)} _hover={{ cursor: 'pointer' }}>
                        <Center display={'flex'} flexDirection={'column'} justify={'center'} alignItems={'center'}>
                            <Box p={3} border={(selectedBank.value === bank.paymentCode) ? '1px' : '0px'} borderColor={'#525C76'} boxShadow={"0px 0px 3.5px 0px #1F29371F"} rounded={'full'}>
                                <Image onError={(e) => {
                                    e.target.src = process.env.PUBLIC_URL + "/assets/apm/bank.svg"
                                }} src={bank.logoUrl} h={'50px'} w={'50px'} />
                            </Box>
                            <Typography pb={8} type={'description'} mt={'1'} weight={selectedBank.value === bank.paymentCode ? "bold" : "regular"} color={(selectedBank.value === bank.paymentCode) ? 'headertext' : 'secondary'} colorweight={(selectedBank.value === bank.paymentCode) ? '400' : 'default'} >
                                {bank.name}
                            </Typography>
                        </Center>
                    </GridItem>
                )}
            </Grid>
        </Box>
    );
};

export default PaymentMethods;
import { Flex, Box, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';
import Typography from '../../components/Common/Typography';

const LoadingSkeleton = ({ orgType, customerType }) => {
      const flexProps = {
            direction: 'column',
            gap: 7,
            borderLeft: "3px solid",
            borderLeftColor: "#1A4FD6",
            py: '10px',
            px: "10px",
            justify: "center",
            w: "216px"
      };

      const typographyProps = {
            color: "primary",
            type: 'body',
            weight: 'bold',
            _hover: { cursor: 'pointer' }
      };

      return (
            <Box px={8} py={5} bg={'#F2F2F2'} minH={'100vh'} >
                  <Typography color="default" type="largeheader" weight="bold">Settings</Typography>
                  <Flex mt={'40px'} ml={'40px'} gap={6}>
                        {['payout', 'collections'].includes(orgType) ?
                              ['guest'].includes(customerType) ?
                                    <>
                                          <Flex {...flexProps} h={"100px"}>
                                                <Typography {...typographyProps} colorweight="500">Details</Typography >
                                                <Typography {...typographyProps} colorweight="200">Personal Details</Typography >
                                          </Flex>
                                    </> :
                                    <>
                                          <Flex {...flexProps} h={"180px"}>
                                                <Typography {...typographyProps} colorweight="500">Team Management</Typography >
                                                <Typography {...typographyProps} colorweight="200">Finance</Typography >
                                                <Typography {...typographyProps} colorweight="200">Wallet Details</Typography >
                                                <Typography {...typographyProps} colorweight="200">Integration</Typography >
                                          </Flex>
                                    </> :
                              <>
                                    <Flex {...flexProps} h={"100px"}>
                                          <Typography {...typographyProps} colorweight="500">Integration</Typography >
                                          <Typography {...typographyProps} colorweight="200">Currencies</Typography >
                                    </Flex>
                              </>
                        }
                        <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                              <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                                    {Array(6).fill(0).map((_, index) => (
                                          <Skeleton height='60px' />
                                    ))}
                              </Stack>
                        </Box>
                  </Flex>
            </Box>
      );
};

export default LoadingSkeleton;

import { Box, Button, Stack, useToast, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Business from './Business';
import Individual from './Individual';
import { businessCustomerValidationSchema, businessShippingCustomerValidationSchema, individualCustomerValidationSchema, individualShippingCustomerValidationSchema } from './validationSchema';
import Typography from '../../components/Common/Typography';
// import { addCustomer } from '../../store/slices/customerSlice';
import BackModal from './BackModal';
import { getSupportedCountries, updateCustomer } from '../../store/slices/customerSlice';

const EditCustomer = ({ showNextButton }) => {

    const location = useLocation();
    const customerData = location.state.customer;
    const [contactType, setContactType] = useState(customerData.type);
    const [phone, setPhone] = useState(customerData.phone);
    const [selectedOption, setSelectedOption] = useState('');
    const [finalValues, setFinalvalues] = useState('');
    const [addressSame, setAddressSame] = useState(true);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.customer.loading) || false;

    const fiatList = useSelector((state) => state?.customer?.supportedCountries);

    const matchedFiatItem = fiatList.find(item => item.value === customerData.country);
    let firstName, lastName, modifiedPhone;
    if (customerData.type !== 'business') {
        [firstName, lastName] = customerData.name?.split(' ');
    }
    const initialValues = {
        name: customerData.name,
        regNumber: customerData.regNumber,
        country: matchedFiatItem?.value,
        email: customerData.email,
        billingAddress1: customerData.address.billingAddress1 || '',
        billingAddress2: customerData.address.billingAddress2 || '',
        billingCity: customerData.address.billingCity || '',
        billingPincode: customerData.address.billingPincode || '',
        shippingAddress1: customerData.address.shippingAddress1,
        shippingAddress2: customerData.address.shippingAddress2,
        shippingCity: customerData.address.shippingCity,
        shippingPincode: customerData.address.shippingPincode || '',
        type: customerData.type,
        userId: customerData.userId,
        orgId: customerData.orgId,
        firstName: firstName,
        lastName: lastName,
        phone: customerData.phone.substring(3),
        countryObj: matchedFiatItem
    }
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    useEffect(() => {
        dispatch(getSupportedCountries());
    }, []);

    let currentValidationSchema;
    if (addressSame) {
        currentValidationSchema = contactType === 'business' ? businessCustomerValidationSchema : individualCustomerValidationSchema;
    } else {
        currentValidationSchema = contactType === 'business' ? businessShippingCustomerValidationSchema : individualShippingCustomerValidationSchema;
    }

    async function handleSubmit(values, actions) {
        try {
            const payload = { ...values, countryLogo: values.countryObj.logo, phone: phone, };
            const response = await dispatch(updateCustomer(payload));
            if (response?.payload?.success) {
                handleToast(response?.payload?.data?.message || "Customer Updated Successfully", 'success');
                navigate('/contact?tab=collection');
            } else {
                throw ("Something went Wrong");
            }
        } catch (error) {
            handleToast(error, 'error');
            navigate('/contact?tab=collection');
        }
    }

    return (
        <Box px={8} py={5} bg={'#F2F2F2'} minH={'100vh'} >
            {!showNextButton && (
                <Typography color="default" type="largeheader" weight="bold">Edit Customer</Typography>
            )}
            <Box bg={"white"} px={'40px'} py={'20px'} border={'1px'} borderColor={'#E4E4E4'} rounded={'9px'} mt={5}>
                <Typography type='body' weight='medium' color='primary' >
                    Customer Details
                </Typography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validationSchema={currentValidationSchema}
                >
                    {({ isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mt={5}>
                                {contactType === 'business' &&
                                    <Business
                                        fiatList={fiatList}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        initialValues={initialValues}
                                        setFinalvalues={setFinalvalues}
                                        addressSame={addressSame}
                                        setAddressSame={setAddressSame}
                                        isEdit={true}
                                    />}
                                {contactType === 'individual' &&
                                    <Individual
                                        fiatList={fiatList}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        setPhone={setPhone}
                                        initialValues={initialValues}
                                        setFinalvalues={setFinalvalues}
                                        addressSame={addressSame}
                                        setAddressSame={setAddressSame}
                                    />}
                            </Box>
                            <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                                <Button onClick={onOpen} variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"}>
                                    Back
                                </Button>
                                <Button type='submit' textColor={'white'} bg={'#1A4FD6'} size={"sm"} p={"9px 16px 9px 16px"} isLoading={loading} _hover={{ bg: '#1A4FD6' }}>
                                    Update
                                </Button>
                            </Stack>
                        </form>
                    )}
                </Formik>
            </Box>
            <BackModal onClose={onClose} isOpen={isOpen} />
        </Box >
    );
};

export default EditCustomer;

import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { GoUpload } from "react-icons/go";
import { isUndefined } from 'lodash';

import Typography from '../../components/Common/Typography';

const InvoiceUpload = ({ isFileUploadError, setIsFileUploadError }) => {

    const fileInput = useRef(null);
    const toast = useToast();
    const { setFieldValue } = useFormikContext();
    const [fileDetails, setFileDetails] = useState("png, jpeg, pdf only");
    const [loading, setLoading] = useState(false);

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true,
            position: "top",
        });
    };

    const allowedFileTypeMap = {
        "application/pdf": "pdf",
        "image/png": "png",
        "image/jpeg": "jpeg"
    };

    const handleFileUpload = async (event) => {
        setIsFileUploadError(false);
        setLoading(true);
        const file = event.target.files[0];

        if (isUndefined(allowedFileTypeMap[file.type])) {
            handleToast("File Should be png, jpeg, pdf only", "error");
            setLoading(false);
            return;
        }

        setFieldValue("invoice", file);
        setFileDetails(file.name);
        setLoading(false);
    };

    return (
        <Box marginTop={"8px"}>
            <input
                type="file"
                accept="pdf"
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={handleFileUpload}
            />
            <Flex gap={1}>
                <Button variant={"secondary"} size={"sm"} padding={"9px 16px 9px 16px"} w={"154px"} h={"40px"} border={"1px solid #E4E4E4"} marginBottom={"5px"} rightIcon={<GoUpload />} onClick={() => fileInput.current.click()} isLoading={loading} >
                    Upload Invoice
                </Button>
                <Text color={"#F50100"} fontWeight={"bold"}>*</Text>
            </Flex>
            <Typography type='description' weight='regular' color='secondary' colorweight='400' >
                {fileDetails}
            </Typography>
            {isFileUploadError && <Text color={'red.500'} fontSize={'15px'}>Upload invoice is mandatory</Text>}
        </Box>
    );
};

export default InvoiceUpload;
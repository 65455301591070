import React, { useState } from 'react';
import { Box, Flex, Icon, Img, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { InfoIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import CustomPopover from '../Popover';

/**
 * 
 * @param {labels} labels array of strings 
 * @param {children} children TBody component 
 */
const StickyTable = ({ maxH, labels = [], upperCaseHeadings = true, children }) => {

    //by default TH texts are in uppercase, this prop will prevent text tranform to uppercase
    const extraHeadingProps = upperCaseHeadings ? {} : {"textTransform" : "none", "fontSize" : "14"};
    return (
        <Box>
            <TableContainer maxH={maxH || "70vh"} overflowY="auto">
                <Table>
                    <Thead bg={"white"} position={"sticky"} top={0} zIndex={"sticky"}>
                        <Tr>
                            {labels.map((label) => (
                                <Th {...extraHeadingProps} fontWeight="normal" paddingY={6} color={"brand.greySecondary"} key={label.label}>
                                    <Box display={"flex"} alignItems={"center"} rowGap={4} columnGap={1} w={"max-content"}>
                                        {label.label}
                                        {label.info && (
                                            <Tooltip fontSize={"12px"} p={"8px 10px"} textColor={"black"} bg={"brand.white"} label={label.info} placement='top'>
                                                <InfoOutlineIcon w={5} h={3.5} color='brand.greySecondary' />
                                            </Tooltip>

                                        )}
                                    </Box>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    {/* Body */}
                    {children}
                </Table>
            </TableContainer>
        </Box>
    );
};

export default StickyTable;

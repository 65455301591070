import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Image, Select, Skeleton } from '@chakra-ui/react';
import EmptyIcon from '../../assets/emptypage.svg';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import { getPayoutOrderData } from '../../store/slices/transactionSlices';

// CSS
import './approval.css';
import ApprovalCard from './ApprovalCard';

const Approval = () => {
  const [typeFilter, setTypeFilter] = useState("all");
  const [approvalsList, setApprovalsList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reduxApprovals = useSelector((state) => state?.transaction?.list || []);
  const transactionLoaderState = useSelector((state) => state?.transaction?.loading || false);

  const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];

  useEffect(() => {
    if (!["admin", "approver"].includes(customerType)) {
      navigate(-1);
    }
  }, [])

  useEffect(() => {
    if (typeFilter == "all") {
      setApprovalsList(reduxApprovals);
    } else {
      if (typeFilter == "individual_pay") {
        const individualFilter = (item) => (item?.type === "fiat_payout" || item?.type === "crypto_payout");
        setApprovalsList(reduxApprovals?.filter(individualFilter));
      } else if (typeFilter == "collect_money") {
        const collectionsFilter = (item) => (item?.type === "collections");
        setApprovalsList(reduxApprovals?.filter(collectionsFilter));
      }
    }
  }, [reduxApprovals, typeFilter]);

  const fetchApprovals = useCallback(() => {
    dispatch(getPayoutOrderData({ filters: { status: "approval_pending" } }));
  }, [dispatch]);

  useEffect(() => {
    fetchApprovals();
  }, [])

  return (
    <Box className='al-main-card'>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography type='largeheader' weight='bold' >
          Approvals
        </Typography>
        <Select width="auto" bg="white" value={typeFilter} onChange={e => setTypeFilter(e.target.value)}>
          <option value='all'>All</option>
          <option value='individual_pay'>Individual Payments</option>
          <option value='collect_money'>Collect Money</option>
        </Select>
      </Flex>
      {!transactionLoaderState ?
        approvalsList.length > 0 ?
          approvalsList.map((item) => {
            return <ApprovalCard
              approvalTransaction={item}
              displayName={item?.paymentAccountDetails?.displayName}
              logo={item.paymentAccountDetails.logo}
              approvalPendingTime={item?.timestamps?.approvalPendingAt}
            />
          }) : <>
            <Flex bg={'white'} width={'full'} minHeight={'100vh'} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'} h={'full'} direction={'column'}>
              <Image src={EmptyIcon} mb={'2'} />
              <Typography color="secondary" colorweight='800' type="title" weight="medium">No Approvals requested yet</Typography>
            </Flex>
          </>
        :
        <>
          <Skeleton height={"135px"} />
          <Skeleton height={"135px"} />
          <Skeleton height={"135px"} />
        </>
      }
    </Box>
  );
}

export default Approval;
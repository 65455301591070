import { Box, Button, Radio, RadioGroup, Stack, useToast, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Business from './Business';
import Individual from './Individual';
import { businessCustomerValidationSchema, businessShippingCustomerValidationSchema, individualCustomerValidationSchema, individualShippingCustomerValidationSchema } from './validationSchema';
import Typography from '../../components/Common/Typography';
import { addCustomer, getSupportedCountries } from '../../store/slices/customerSlice';
import BackModal from './BackModal';

const AddCustomer = ({ showNextButton, setShowAddCustomer }) => {
    const [contactType, setContactType] = useState('business');
    const [phone, setPhone] = useState('');
    const [selectedOption, setSelectedOption] = useState({ label: "Select Country" })
    const [addressSame, setAddressSame] = useState(true);

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const loading = useSelector((state) => state.customer.loading) || false;
    const fiatList = useSelector((state) => state?.customer?.supportedCountries);

    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true,
            position: 'top'
        });
    };

    useEffect(() => {
        dispatch(getSupportedCountries());
    }, [])

    const initialValue = {
        country: '',
        name: '',
        regNumber: '',
        email: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingPincode: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingPincode: '',
        firstName: '',
        lastName: '',
        nickname: '',
        countryLogo: '',
    };

    let currentValidationSchema;

    if (addressSame) {
        currentValidationSchema = contactType === 'business' ? businessCustomerValidationSchema : individualCustomerValidationSchema;
    } else {
        currentValidationSchema = contactType === 'business' ? businessShippingCustomerValidationSchema : individualShippingCustomerValidationSchema;
    }

    async function handleSubmit(values, actions) {
        const response = await dispatch(addCustomer({ ...values, type: contactType, product: 'collection', countryLogo: selectedOption?.logo, phone: phone }));

        if (response?.payload?.data?.success) {
            handleToast(response?.payload?.data?.message || 'Added Successfully', 'success');
            if (showNextButton) {
                setShowAddCustomer(false);
                navigate('/collection')
            } else {
                navigate('/contact?tab=collection');
            }

        } else {
            if (response?.payload?.response?.data?.error) {
                handleToast(response?.payload?.response?.data?.error?.code || 'Something went wrong', 'error')
                navigate('/contact?tab=collection');
            }
            handleToast(response?.payload?.error?.message || 'Something went wrong', 'error')
            if (showNextButton) {
                navigate('/collection')
            } else {
                navigate('/contact?tab=collection');
            }

        }
    }

    return (
        <Box px={8} py={5} bg={showNextButton ? 'white' : '#F2F2F2'} minH={'100vh'} >
            {!showNextButton && (
                <Typography color="default" type="largeheader" weight="bold">Add Collections Contact</Typography>
            )}
            <Box bg={"white"} px={'40px'} py={'20px'} border={showNextButton ? '0px' : '1px'} borderColor={'#E4E4E4'} rounded={'9px'} mt={5}>
                <Typography type='body' weight='medium' color='primary' >
                    Contact Details
                </Typography>
                <Formik
                    initialValues={initialValue}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validationSchema={currentValidationSchema}
                >
                    {({ isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <RadioGroup onChange={setContactType} value={contactType} mt={5}>
                                <Typography type='description' weight='regular' color='secondary' colorweight='800'>
                                    Contact Type
                                </Typography>
                                <Stack direction='row' mt={2} gap={10}>
                                    <Radio value='business'>Business</Radio>
                                    <Radio value='individual'>Individual</Radio>
                                </Stack>
                            </RadioGroup>

                            <Box mt={5}>
                                {contactType === 'business' &&
                                    <Business
                                        fiatList={fiatList}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        addressSame={addressSame}
                                        setAddressSame={setAddressSame}
                                    />}
                                {contactType === 'individual' &&
                                    <Individual
                                        fiatList={fiatList}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        setPhone={setPhone}
                                        addressSame={addressSame}
                                        setAddressSame={setAddressSame}
                                    />}
                            </Box>
                            <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                                {!showNextButton && (
                                    <Button onClick={onOpen} variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"}>
                                        Back
                                    </Button>
                                )}
                                {showNextButton && (
                                    <Button type='submit' textColor={'white'} bg={'#1A4FD6'} size={"sm"} p={"9px 16px 9px 16px"} isLoading={loading} _hover={{ bg: '#1A4FD6' }}>
                                        Next
                                    </Button>
                                )}
                                {!showNextButton && (
                                    <Button type='submit' textColor={'white'} bg={'#1A4FD6'} size={"sm"} p={"9px 16px 9px 16px"} isLoading={loading} _hover={{ bg: '#1A4FD6' }}>
                                        Confirm
                                    </Button>
                                )};

                            </Stack>
                        </form>
                    )}

                </Formik>

            </Box>

            <BackModal onClose={onClose} isOpen={isOpen} />
        </Box >
    );
};

export default AddCustomer;
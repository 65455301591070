import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Button, Flex, HStack, Img, SimpleGrid, VStack, useDisclosure } from '@chakra-ui/react';
import { IoEyeOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { CheckIcon } from '@chakra-ui/icons';
import { round } from 'lodash';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import { getPayoutOrderData } from '../../store/slices/transactionSlices';
import TransactionModal from './modals/TransactionModal';

// CSS
import './approval.css';
import { currencyFormatter, formatDateTime } from '../../utils/formatter';

const iconCollectMoney = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_47_2775)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.8125 0H24.1875C28.4905 0 32 3.5095 32 7.8125V24.1875C32 28.4905 28.4905 32 24.1875 32H7.8125C3.5095 32 0 28.4905 0 24.1875V7.8125C0 3.5095 3.5095 0 7.8125 0ZM22.8746 7.05431L16 13.9284L9.12544 7.05431C8.98974 6.91696 8.82822 6.80779 8.65017 6.73308C8.47212 6.65838 8.28106 6.61961 8.08798 6.61902C7.8949 6.61843 7.7036 6.65602 7.5251 6.72963C7.3466 6.80324 7.18441 6.91141 7.04787 7.04793C6.91133 7.18445 6.80313 7.34662 6.72949 7.52511C6.65586 7.70361 6.61824 7.8949 6.6188 8.08798C6.61937 8.28106 6.6581 8.47213 6.73278 8.65019C6.80746 8.82824 6.9166 8.98978 7.05394 9.1255L14.9634 17.0356C15.0993 17.1717 15.2608 17.2797 15.4385 17.3534C15.6162 17.427 15.8067 17.4649 15.9991 17.4649C16.1915 17.4649 16.382 17.427 16.5597 17.3534C16.7374 17.2797 16.8989 17.1717 17.0349 17.0356L24.9461 9.1255C25.218 8.85024 25.37 8.47855 25.3688 8.09161C25.3676 7.70468 25.2133 7.33393 24.9397 7.06034C24.6661 6.78676 24.2953 6.63256 23.9084 6.63143C23.5214 6.6303 23.1498 6.78233 22.8746 7.05431ZM24.9461 14.9644C24.8101 14.8283 24.6486 14.7204 24.4709 14.6467C24.2932 14.5731 24.1027 14.5351 23.9103 14.5351C23.7179 14.5351 23.5274 14.5731 23.3497 14.6467C23.172 14.7204 23.0105 14.8283 22.8746 14.9644L16 21.8385L9.12544 14.9644C8.98974 14.8271 8.82822 14.7179 8.65017 14.6432C8.47212 14.5685 8.28106 14.5297 8.08798 14.5291C7.8949 14.5286 7.7036 14.5661 7.5251 14.6398C7.3466 14.7134 7.18441 14.8215 7.04787 14.9581C6.91133 15.0946 6.80313 15.2567 6.72949 15.4352C6.65586 15.6137 6.61824 15.805 6.6188 15.9981C6.61937 16.1912 6.6581 16.3823 6.73278 16.5603C6.80746 16.7384 6.9166 16.8999 7.05394 17.0356L14.9634 24.9457C15.0993 25.0819 15.2608 25.1898 15.4385 25.2635C15.6162 25.3372 15.8067 25.3751 15.9991 25.3751C16.1915 25.3751 16.382 25.3372 16.5597 25.2635C16.7375 25.1898 16.8989 25.0819 17.0349 24.9457L24.9461 17.0356C25.2207 16.761 25.375 16.3884 25.375 16C25.375 15.6116 25.2207 15.2391 24.9461 14.9644Z" fill="url(#paint0_linear_47_2775)" />
  </g>
  <defs>
    <linearGradient id="paint0_linear_47_2775" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
      <stop stop-color="#BACAF3" />
      <stop offset="1" stop-color="#7291E0" />
    </linearGradient>
    <clipPath id="clip0_47_2775">
      <rect width="32" height="32" fill="white" />
    </clipPath>
  </defs>
</svg>

const iconIndPayment = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_47_2773)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.8125 0H24.1875C28.4905 0 32 3.5095 32 7.8125V24.1875C32 28.4905 28.4905 32 24.1875 32H7.8125C3.5095 32 0 28.4905 0 24.1875V7.8125C0 3.5095 3.5095 0 7.8125 0ZM6.625 15.9991C6.62489 16.1915 6.66271 16.3821 6.73628 16.5599C6.80986 16.7377 6.91775 16.8992 7.0538 17.0353C7.18984 17.1713 7.35137 17.2793 7.52915 17.3529C7.70692 17.4265 7.89746 17.4643 8.08988 17.4643H20.3737L14.9642 22.8737C14.8282 23.0097 14.7203 23.1712 14.6467 23.3489C14.5731 23.5266 14.5352 23.7171 14.5352 23.9095C14.5353 24.1018 14.5731 24.2923 14.6468 24.47C14.7204 24.6478 14.8283 24.8092 14.9643 24.9452C15.239 25.2199 15.6116 25.3743 16.0001 25.3743C16.1925 25.3742 16.3829 25.3364 16.5607 25.2627C16.7384 25.1891 16.8999 25.0812 17.0359 24.9452L24.9459 17.0357C25.082 16.8997 25.1899 16.7383 25.2635 16.5606C25.3371 16.3828 25.375 16.1924 25.375 16C25.375 15.8076 25.3371 15.6172 25.2635 15.4394C25.1899 15.2617 25.082 15.1003 24.9459 14.9642L17.0359 7.05481C16.7612 6.7801 16.3886 6.62576 16.0001 6.62575C15.6116 6.62573 15.239 6.78005 14.9643 7.05475C14.6896 7.32945 14.5353 7.70202 14.5352 8.09052C14.5352 8.47901 14.6896 8.8516 14.9642 9.12631L20.3737 14.5358H8.08988C7.8976 14.5357 7.70718 14.5734 7.5295 14.6469C7.35181 14.7204 7.19034 14.8281 7.05431 14.964C6.91828 15.0999 6.81035 15.2613 6.73669 15.4389C6.66303 15.6165 6.62507 15.8068 6.625 15.9991Z" fill="url(#paint0_linear_47_2773)" />
  </g>
  <defs>
    <linearGradient id="paint0_linear_47_2773" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
      <stop stop-color="#BACAF3" />
      <stop offset="1" stop-color="#7291E0" />
    </linearGradient>
    <clipPath id="clip0_47_2773">
      <rect width="32" height="32" fill="white" />
    </clipPath>
  </defs>
</svg>


const ApprovalCard = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let {displayName, logo, approvalPendingTime = '' } = props;
  let { makerName, contactName, orderId,  depositAmount, depositCurrency, withdrawAmount, withdrawCurrency, type: orderType } = props.approvalTransaction;

  const [type, setType] = useState("");

  approvalPendingTime = new Date(approvalPendingTime);
  const add24Hours = new Date(approvalPendingTime.getTime() + (24 * 60 * 60 * 1000));

  const formattedTime = formatDateTime(add24Hours);

  const handleOpenRejectModal = () => {
    setType("reject");
    onOpen();
  }

  const handleOpenApproveModal = () => {
    setType("approve");
    onOpen();
  }

  const handleNavigateToSummary = () => {
    navigate('/approvals/summary', { state: { orderId } });
  }

  const fetchApprovals = useCallback(() => {
    dispatch(getPayoutOrderData({ filters: { status: "approval_pending" } }));
  }, [dispatch]);

  return (
    <Box className='al-approval-card'>
      <VStack height={"100%"} width={"100%"} display={"flex"} gap={"16px"} >
        <Flex width="100%" justifyContent={"space-between"} alignItems={"center"} >
          <HStack height={"34px"} >
            {orderType == "collections" ? iconCollectMoney : iconIndPayment}
            <VStack display={"flex"} flexDirection={"column"} gap={"0px"} justifyContent={"center"} alignItems={"flex-start"} >
              <Typography type='description' weight='regular'>
                {orderType == "collections" ? "Collect Money" : "Individual Payment"}
              </Typography>
              <Typography type='caption' weight='regular' color='secondary' >
                - {makerName}
              </Typography>
            </VStack>
          </HStack>
          <HStack display={"flex"} flexDirection={"row"} gap={"20px"} >
            <HStack display={"flex"} flexDirection={"row"} gap={"4px"} >
              <Img src={process.env.PUBLIC_URL + "/assets/approval_pending_calendar.png"} alt='calendarLogo' />
              <Typography type='caption' weight='regular' color='error' >
                Due by {formattedTime}
              </Typography>
            </HStack>
            <Box className='al-transaction-details' onClick={handleNavigateToSummary} >
              <IoEyeOutline className='al-approval-eye-img' />
            </Box>
            <Button variant={"danger"} size={"sm"} rightIcon={<RxCross2 width={"10px"} height={"10px"} />} onClick={handleOpenRejectModal}  >
              Reject
            </Button>
            <Button variant={"primary"} size={"sm"} rightIcon={<CheckIcon width={"10px"} height={"10px"} />} onClick={handleOpenApproveModal} >
              Approve
            </Button>
          </HStack>
        </Flex>
        <SimpleGrid width="100%" columns={3} alignItems={"center"} height={"45px"} >
          <HStack display={"flex"} alignItems={"center"} >
            <Img src={process.env.PUBLIC_URL + logo} alt='countryLogo' width={"32px"} height={"32px"} />
            <VStack display={"flex"} flexDirection={"column"} gap={"0px"} alignItems={"flex-start"} >
              <Typography type='body' weight='bold' >
                {`${contactName} (${displayName})`}
              </Typography>
              <Typography type='caption' weight='regular' color='secondary' >
                Txn Id - {orderId}
              </Typography>
            </VStack>
          </HStack>
          <Box display={"flex"} flexDir={"column"} alignItems={"end"} gap={"0px"} pr={5} >
            <Typography type='description' weight='regular' color='secondary' >
              {`${orderType == "collections" ? "You" : "They"} receive`}
            </Typography>
            <Typography type='body' weight='medium' color='secondary' colorweight={"800"} >
              {`${currencyFormatter(withdrawAmount)} ${withdrawCurrency}`}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"flex-end"} flexDir={"column"} gap={"0px"} >
            <Typography type='description' weight='regular' color='secondary' >
              {`${orderType == "collections" ? "Invoice amount" : "You pay"}`}
            </Typography>
            <Typography type='body' weight='bold' color='primary' >
              {`${depositCurrency === "BTC" ? currencyFormatter(depositAmount, 5) : currencyFormatter(depositAmount)} ${depositCurrency}`}
            </Typography>
          </Box>
        </SimpleGrid>
      </VStack>
      <TransactionModal onClose={onClose} refreshData={fetchApprovals} isOpen={isOpen} type={type} data={{ orderId, contactName, accountName: displayName, paidFrom: depositCurrency, depositCurrency, depositAmount }} />
    </Box>
  );
}

export default ApprovalCard;